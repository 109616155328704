<template>
    <h1>{{ $tc('logging_out', 1) }}</h1>
</template>

<script>
    export default {
        async created() {
            await this.$store.dispatch('auth/logout');

            this.$router.replace({
                name: 'login',
            });
        },
    };
</script>
